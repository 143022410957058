import React from "react";
import styled from "styled-components";
import {mediaQuery} from "components/common/styled/mediaQuery";

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;

    ${props =>
        !props.isSms &&
        `
       @media (max-width: 1220px) and (min-width: 1033px) {
            background: var(--main-theme-gradient);
        }
    `}

    ${props =>
        props.isSms &&
        `
      position: fixed;
      background: white;
      top: 0;
      z-index:2;
    `}
    width: 100%;
    height: 4rem;

    ${props =>
        !props.isSms &&
        mediaQuery.phone`
       background: var(--main-theme-gradient);
    `}
`;

const HeaderContent = styled.div`
    height: 100%;
    max-width: 100%;
    margin: 0 0.3rem;
    width: 100%;
`;

export default props => (
    <HeaderWrapper isSms={props.isSms}>
        <HeaderContent>{props.children}</HeaderContent>
    </HeaderWrapper>
);
